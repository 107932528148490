import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"

import Seo from "../components/seo"
import { navigate } from "gatsby"
import { Player } from "@lottiefiles/react-lottie-player"

const pageTitle = "برخی از پروژه های انجام شده"

export default function projects() {
  return (
    <Layout>
      <Seo
        title={pageTitle}
        description="برخی از پروژه های انجام شده شرکت مشارکت نیرو آریا کیش"
      />
      <div className="container px-0 sm:px-2">
        <section
          id="projects-hero"
          className="grid grid-cols-2 px-5 sm:grid-cols-1 items-center sm:px-2  sm:py-10"
        >
          <div
            id="hero-animation"
            className="border rounded-md px-2 m-5 sm:m-2 shadow-lg shadow-gray-300"
          >
            <Player
              autoplay
              loop
              src="https://assets1.lottiefiles.com/packages/lf20_kujqck0u.json"
            ></Player>
          </div>
          <div className="flex flex-col items-center space-y-6">
            <h1 className="text-5xl sm:text-4xl font-semibold text-primary">
              {pageTitle}
            </h1>
            <h2 className="text-2xl sm:text-xl font-semibold text-primary">
              این شرکت مفتخر است که بسیاری ازپروژه های انرژی را در اقصی نقاط
              کشور اجرا نموده است .
            </h2>
            <p className="text-lg">
              ارائه پشتیبانی مناسب و خدمات پس از فروش مربوطه نظیر آموزش، تامین
              قطعات مصرفی و یدکی، باعث می شود که مشتریان این شرکت، افتخار همراهی
              همیشگی را به شرکت مشارکت نیرو بدهند. ما از ابتدا تا انتهای راه،
              همراه باوفای شما خواهیم بود.
            </p>
            <button
              className="px-10 py-4 bg-secondary text-xl text-white max-w-max rounded shadow-lg shadow-black hover:bg-primary"
              onClick={() => {
                navigate("/contact-us")
              }}
            >
              تماس با ما
            </button>
          </div>
        </section>
        <section id="projects" className="bg-black text-white py-5">
          <h3 className="text-4xl text-center my-5">
            سه نمونه از پروژه های انجام شده
          </h3>

          <div
            id="projects-details"
            className="grid gap-5 m-10 grid-cols-3 sm:grid-cols-1 sm:gap-10"
          >
            <div
              id="shirin-project"
              className="border border-secondary p-5 rounded shadow-lg shadow-gray-100"
            >
              <h3 className="text-center -mt-9 text-white bg-secondary border border-secondary rounded font-bold text-xl shadow-lg shadow-gray-100">
                پروژه مجتمع شیرین
              </h3>
              <StaticImage
                src="../images/projects/Shirin Project.jpeg"
                alt="Shirin Project"
                className="px-5 py-5"
              />
              <h4 className="text-2xl">
                پروژه تامین برق اضطراری مجتمع تجاری و اداری
              </h4>
              <br />
              <ul className="text-right">
                <li>ظرفیت : 0.8 مگاوات</li>
                <li>محل پروژه : تهران</li>
                <li>نوع مولد : Volvo-MeccAlte (Silent)</li>
                <li>نوع پروژه : تامین برق به صورت EPC</li>
              </ul>
            </div>

            <div
              id="hurmuz-project"
              className="border border-secondary p-5 rounded shadow-lg shadow-gray-100 "
            >
              <h3 className="text-center -mt-9 text-white bg-secondary border border-secondary rounded font-bold text-xl shadow-lg shadow-gray-100">
                پروژه جزیره هرمز
              </h3>
              <StaticImage
                src="../images/projects/Hurmuz Project.jpeg"
                alt="Hurmuz Project"
                className="px-5 py-5"
                placeholder="blurred"
              />
              <h4 className="text-2xl">
                پروژه تامین برق جزیره هرمز (در دست اجرا)
              </h4>
              <br />
              <ul className="text-right">
                <li>ظرفیت : 11.5 مگاوات</li>
                <li>محل : جزیره هرمز</li>
                <li>نوع مولد : Mitsubishi 18KU30A</li>
                <li>نوع پروژه :تامین برق بصورت EPC</li>
              </ul>
            </div>

            <div
              id="abumusa-project"
              className="border border-secondary p-5 rounded shadow-lg shadow-gray-100"
            >
              <h3 className="text-center -mt-9 text-white bg-secondary border border-secondary rounded font-bold text-xl shadow-lg shadow-gray-100">
                پروژه جزیره ابوموسی
              </h3>
              <StaticImage
                src="../images/projects/Abumusa Project.jpeg"
                alt="Abumusa Project"
                className="px-5 py-5"
              />
              <h4 className="text-2xl">
                پروژه تامین برق جزیره ابوموسی در خلیج فارس
              </h4>
              <br />
              <ul className="text-right">
                <li>ظرفیت : 10 مگاوات</li>
                <li>محل : جزیره ابوموسی</li>
                <li>نوع مولد : Cummins Power KTA50 (Silent)</li>
                <li>نوع پروژه : تامین برق به صورت EPC</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
